import React, { useEffect } from "react";
import { useStopwatch } from "react-timer-hook";

const Stopwatch = ({
  limit = { minutes: 0, seconds: 30 },
  callback = () => {},
}) => {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    reset,
    resume,
    restart,
  } = useStopwatch({
    autoStart: false,
  });

  useEffect(() => {
    start();

    return reset();
  }, []);

  useEffect(() => {
    if (limit.seconds === seconds && limit.minutes === minutes) {
      callback();
    }
  }, [seconds, minutes]);

  return (
    <>
      {minutes.toString().padStart(2, "0")}:
      {seconds.toString().padStart(2, "0")}
    </>
  );
};

export default Stopwatch;
