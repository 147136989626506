import React, { useState, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { Typography, TextField, InputAdornment, Tooltip } from "@mui/material";
import {
  QuestionMarkRounded,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import {
  crowdsourcerReducer,
  initCrowdsourcerReducer,
} from "../utils/reducers";
import CentralCard from "../components/CentralCard";
import { LoginTextfieldThemeProvider } from "../utils/themes";
import { Link, useNavigate } from "react-router-dom";
import { getFloatFixedValue } from "../utils/functions";

function LoginRegister() {
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(crowdsourcerReducer, {
    ...initCrowdsourcerReducer,
  });
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [usernameHelperText, setUsernameHelperText] = useState(
    "Username must be unique"
  );
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [creationCost, setCreationCost] = useState();

  const routerNavigate = useNavigate();

  useEffect(() => {
    const checkLogin = async () => {
      try {
        //await axios.get(
        //  process.env.REACT_APP_BASE_URL + "/register/checkLogin"
        //);
        //routerNavigate("/crowdsourcer", { replace: true });
      } catch (err) { }
    };

    checkLogin();
  }, []);

  useEffect(() => {
    checkInput();
  }, [usernameError, state]);

  const updateData = (e) => {
    dispatch({ type: e.target.id, [e.target.id]: e.target.value });
  };

  const checkUsername = async (e) => {
    dispatch({ type: e.target.id, [e.target.id]: e.target.value });
    let res = await axios.get(
      process.env.REACT_APP_BASE_URL + "/register/checkUsername",
      {
        params: { username: e.target.value },
      }
    );

    setUsernameError(!res.data.available);
    setUsernameHelperText(
      res.data.available ? "Username is available" : "Username is taken"
    );
  };

  const checkInput = () => {
    if (!Object.values(state).every((el) => el !== "" && !usernameError)) {
      setEnableSubmit(false);
    } else {
      setEnableSubmit(true);
    }
  };

  const submit = async (e) => {
    setButtonLoading(true);
    e.preventDefault();
    //state.password = bcrypt.hashSync(state.password, 10);
    await axios
      .post(
        process.env.REACT_APP_BASE_URL + "/register/createCrowdsourcer",
        state,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        dispatch({ type: "reset" });
        enqueueSnackbar("Account created successfully", {
          variant: "success",
        });
        routerNavigate("/crowdsourcer");
      })
      .catch((err) => {
        enqueueSnackbar(
          `Error while creating account (${err.response.data.message})`,
          {
            variant: "error",
          }
        );
      });

    setButtonLoading(false);
  };

  useEffect(() => {
    if (state.pricePerPosition < 0.1) {
      setCreationCost(Math.round((state.pricePerPosition + 0.01) * 100) / 100);
    } else {
      setCreationCost(
        // gotten from MW code
        (
          getFloatFixedValue(1 * state.pricePerPosition) *
          (1 + 10 / 100)
        ).toFixed(2)
      );
    }
  }, [state.pricePerPosition]);

  return (
    <CentralCard maxWidth={"md"} component={"form"}>
      <Typography variant="h5">Register as a new user</Typography>
      {/* <ThemeProvider theme={theme}> */}
      <LoginTextfieldThemeProvider>
        <TextField
          onChange={updateData}
          id="name"
          label="Name"
          value={state.name}
        ></TextField>
        <TextField
          onChange={updateData}
          id="surname"
          label="Surname"
          value={state.surname}
        ></TextField>
        <TextField
          error={usernameError}
          onChange={async (e) => {
            await checkUsername(e);
          }}
          id="username"
          label="Username"
          helperText={usernameHelperText}
          value={state.username}
        ></TextField>
        <TextField
          onChange={updateData}
          id="password"
          type={showPassword ? "text" : "password"}
          label="Password"
          value={state.password}
          InputProps={{
            endAdornment: (
              <InputAdornment
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                position="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </InputAdornment>
            ),
          }}
        ></TextField>
        <TextField
          onChange={(e) => {
            e.target.value = e.target.value.trim();
            updateData(e);
          }}
          id="apiKey"
          label="API Key"
          value={state.apiKey}
          InputProps={{
            endAdornment: (
              <>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={() => {
                    setShowTooltip(false);
                  }}
                  open={showTooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={
                    <Typography sx={{ textAlign: "center" }}>
                      You can find your API key in your{" "}
                      <a
                        href="https://www.microworkers.com/account.php"
                        target="_blank"
                        style={{
                          color: "white",
                          textDecoration: "underline",
                          fontStyle: "italic",
                        }}
                      >
                        Account page
                      </a>{" "}
                      on Microworkers
                    </Typography>
                  }
                >
                  <QuestionMarkRounded
                    onClick={() => {
                      setShowTooltip(!showTooltip);
                    }}
                  />
                </Tooltip>
              </>
            ),
          }}
        ></TextField>
        <TextField
          onChange={updateData}
          id="campaignNameMW"
          label="Microworkers Campaign Name"
          value={state.campaignNameMW}
          InputProps={{
            endAdornment: (
              <>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={() => {
                    setShowTooltip2(false);
                  }}
                  open={showTooltip2}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={
                    <Typography sx={{ textAlign: "center" }}>
                      The name that will be given to the Microworkers Campaign
                      related to your crowdsensing campaigns
                    </Typography>
                  }
                >
                  <QuestionMarkRounded
                    onClick={() => {
                      setShowTooltip2(!showTooltip2);
                    }}
                  />
                </Tooltip>
              </>
            ),
          }}
        ></TextField>
        <TextField
          onChange={(e) => {
            const val = Number(parseFloat(e.target.value).toFixed(2));
            if (val >= 0) {
              const tmpe = {
                target: {
                  id: "pricePerPosition",
                  value: val,
                },
              };
              updateData(tmpe);
            }
          }}
          id="pricePerPosition"
          label="Price per Position"
          type="number"
          value={state.pricePerPosition}
          inputProps={{
            step: "0.01",
          }}
        ></TextField>
        <Typography sx={{ mb: "0.8em" }} variant="h6">
          Creation cost: {creationCost}{" "}
          <Tooltip title="When you create an account, we automatically create a MW campaign with 1 position. All you crowdsensing campaign will be related to this MW campaign">
            <InfoIcon fontSize="15" sx={{ pt: "0.1em", color: "gray" }} />
          </Tooltip>
        </Typography>

        {/* </ThemeProvider> */}
      </LoginTextfieldThemeProvider>

      <LoadingButton
        disabled={!enableSubmit}
        loading={buttonLoading}
        type="submit"
        onClick={submit}
        variant="contained"
        size="large"
      >
        register
      </LoadingButton>
      <Typography variant="caption" sx={{ my: "0.5em", textAlign: "center" }}>
        NOTE: The price per position will not be editable after registration.
        Also, the Microworkers campaign will be a Hire-group type, and the group
        will default to "ALL EU Workers".
      </Typography>
      <Typography sx={{ my: "0.5em" }}>
        Already have an account?{" "}
        <Link to="/" replace={true}>
          Login
        </Link>
      </Typography>
    </CentralCard>
  );
}

export default LoginRegister;
