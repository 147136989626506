import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Container,
  Paper,
  Box,
  Card,
  CardContent,
  CardActions,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";

function Main() {
  const routerNavigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCampaigns();
  }, []);

  const getCampaigns = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_BASE_URL + "/crowdsourcer/getCampaigns"
      );
      setCampaigns(res.data);
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to retrive campaigns", { variant: "error" });
    }
    setLoading(false);
  };

  return (
    <Container maxWidth="100%" sx={{ padding: "1em" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper sx={{ padding: "1em", minHeight: "100vh" }}>
        <Typography variant="h5" sx={{ mb: "0.5em" }}>
          Your Crowdsensing Campaigns
        </Typography>
        <Box
          sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
        >
          {campaigns.map((val, index) => {
            return (
              <CampaignCard
                {...val}
                key={index}
                setLoading={setLoading}
                enqueueSnackbar={enqueueSnackbar}
                routerNavigate={routerNavigate}
                getCampaigns={getCampaigns}
              />
            );
          })}

          {/* add campaign */}
          <Card
            sx={{ p: "0.5em", m: "0.4em", width: "20em" }}
            elevation={1}
            onClick={() => {
              routerNavigate("/crowdsourcer/createCampaign");
            }}
          >
            <Container
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AddCircleIcon sx={{ fontSize: 60, color: "grey" }} />
              <Typography sx={{ color: "grey", fontSize: 25 }}>
                Add a new campaign
              </Typography>
            </Container>
          </Card>
        </Box>
      </Paper>
    </Container>
  );
}

const CampaignCard = (props) => {
  const [openEdit, setOpenEdit] = useState(false);

  return (
    <>
      <EditDialog {...props} openEdit={openEdit} setOpenEdit={setOpenEdit} />
      <Card sx={{ p: "0.5em", m: "0.4em", width: "20em" }} elevation={5}>
        <CardContent>
          <Typography noWrap={true} sx={{ fontWeight: "bold" }}>
            {props.title}
          </Typography>
          <Typography>
            Completed: {props.completedJobs} / {props.totalJobs}
          </Typography>
          <Typography>Waiting for Rate: {props.readyToRateJobs}</Typography>
          <Typography>Active jobs: {props.activeJobs}</Typography>
          <Typography>Canceled jobs: {props.canceledJobs}</Typography>
          <Typography>Failed: {props.failedJobs}</Typography>
        </CardContent>
        <CardActions>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Button
              variant="contained"
              sx={{ width: "100%", my: "0.3em" }}
              color="warning"
              onClick={() => {
                setOpenEdit(true);
              }}
            >
              edit
            </Button>
            <Button
              variant="contained"
              sx={{ width: "100%", my: "0.3em" }}
              color="success"
              onClick={() => {
                props.routerNavigate(`/crowdsourcer/showData/${props._id}`);
              }}
            >
              show data
            </Button>
          </Box>
        </CardActions>
      </Card>
    </>
  );
};

const EditDialog = (props) => {
  const [title, setTitle] = useState("");
  const [descr, setDescr] = useState("");

  useEffect(() => {
    if (props) {
      setTitle(props.title);
      setDescr(props.description);
    }
  }, [props]);

  const handleSave = async () => {
    if (title === props.title && descr === props.description) {
      props.setOpenEdit(false);
      return;
    }
    props.setLoading(true);
    props.setOpenEdit(false);
    try {
      await axios.post(
        process.env.REACT_APP_BASE_URL + "/crowdsourcer/editCampaign",
        { id: props._id, title: title, description: descr }
      );
      props.enqueueSnackbar("Campaign information updated!", {
        variant: "success",
      });
      props.getCampaigns();
    } catch (err) {
      console.error(err);
      props.enqueueSnackbar("Failed to save changes", { variant: "error" });
    }
    props.setLoading(false);
  };

  return (
    <Dialog
      open={props.openEdit}
      onClose={() => {
        props.setOpenEdit(false);
      }}
    >
      <DialogTitle>Edit Campaign Information</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <TextField
          sx={{ my: "1em" }}
          label="Title"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
        <TextField
          multiline
          rows={4}
          label="Description"
          value={descr}
          onChange={(e) => {
            setDescr(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSave}
          size="small"
          fullWidth
          variant="contained"
          color="success"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Main;
