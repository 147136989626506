import React, { useEffect, useState, useReducer } from "react";
import {
  Button,
  Box,
  Container,
  Card,
  CardContent,
  CardActions,
  Typography,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Alert,
  Backdrop,
  CircularProgress,
  TextField,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";
import * as dfns from "date-fns";
import { useInterval } from "usehooks-ts";
import { MapContainer, TileLayer, Polygon } from "react-leaflet";
import * as L from "leaflet";
import * as turf from "@turf/turf";
import { useGeolocated } from "react-geolocated";
import {
  getSensorHook,
  checkAudioVideo,
} from "../../utils/sensorCheckFunctions";
import { BoundsFitting } from "./CampaignSelect";
import { useRelativeOrientation } from "../../sensors/basicSensorHook";
import { VideoPhotoDialog } from "../../components/Dialogs";
import {
  getCurrentBrowserName,
  checkBrowserSensorSupport,
} from "../../utils/functions";

/**
 * -----------------------------------------------------------------------
 * Sensor Collect Component section
 * Here are created the component (SensorCollector, AudioVideoSensorCollector
 * and eventually QuestionComponent) for data collection
 */
function SensorCollect() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const [currentGeo, setCurrentGeo] = useState({});
  const [geojson, setGeojson] = useState({});
  const [sensorsSpecs, setSensorsSpecs] = useState([]);
  const [campaignDescription, setCampaignDescription] = useState(null);
  const [jobId, setJobId] = useState("");
  const [noJob, setNoJob] = useState(false);
  const [jobStatus, setJobStatus] = useState(null);
  const [failReason, setFailReason] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [gpsEnableDialog, showGpsEnableDialog] = useState(true);
  const [withinBounds, showWithinBounds] = useState(true);
  const [expired, showExpired] = useState(false);
  const [question, setQuestion] = useState(null);
  const [workerAnswer, setWorkerAnswer] = useState(null);

  const {
    coords,
    timestamp,
    isGeolocationAvailable,
    isGeolocationEnabled,
    positionError,
    getPosition,
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: Infinity,
    },
    watchPosition: true,
    userDecisionTimeout: null,
    suppressLocationOnMount: true,
    geolocationProvider: navigator.geolocation,
    isOptimisticGeolocationEnabled: false,
    onError: (error) => {
      showGpsEnableDialog(true);
      console.error(error);
    },
    onSuccess: (position) => {
      showGpsEnableDialog(false);
      setCurrentGeo(position);
    },
  });

  useEffect(() => {
    getPosition();
  }, []);

  useInterval(
    () => {
      checkBounds();
      checkExpired();
    },
    gpsEnableDialog ? null : 500
  );

  useInterval(
    async () => {
      try {
        await axios.post(
          process.env.REACT_APP_WORKER_URL + "/sensorData/postNetworkReading",
          {
            type: navigator.connection.type || "unknown", // Type of internet connection of the device (bluetooth, ethernet, wifi...)
            downlink: navigator.connection.downlink || -1, // Download speed in Mbps
            rtt: navigator.connection.rtt || -1, // Time to send and get response
            jobId: jobId,
          }
        );
      } catch (error) {
        console.error("Failed to send network information: ", error);
      }
    },
    jobId && !jobStatus ? 2000 : null
  );

  const checkBounds = () => {
    if (!geojson.coordinates) {
      return;
    }
    const polygon = L.polygon(geojson.coordinates);
    showWithinBounds(
      polygon.getBounds().contains([coords.latitude, coords.longitude])
    );
  };

  const checkExpired = () => {
    if (dfns.differenceInSeconds(new Date(), new Date(timestamp)) > 10) {
      showExpired(true);
    } else {
      showExpired(false);
    }
  };

  useEffect(() => {
    startUp();
  }, []);

  const jobEndSwitch = () => {
    switch (jobStatus) {
      case "FAILED":
        if (failReason === "expired") {
          return (
            <JobStatusComponent>
              You have run out of time for this job.
            </JobStatusComponent>
          );
        }
        return (
          <JobStatusComponent>
            Your job has been rated as Not OK. You can see the reason in your{" "}
            <a href={`https://ttv-sandbox.microworkers.com`}>Microworkers</a>{" "}
            Profile.
          </JobStatusComponent>
        );

      case "READY_TO_RATE":
        return (
          <JobStatusComponent>
            Your task is waiting to be rated. Thank you for contributing.
          </JobStatusComponent>
        );

      case "RATED":
        return (
          <JobStatusComponent>
            Your task has been rated as OK. Thank you for partecipating to this
            Crowdsensing campaign!
          </JobStatusComponent>
        );

      case "CANCEL":
        return (
          <JobStatusComponent>You have canceled this Job.</JobStatusComponent>
        );

      default:
        setJobId("");
        return (
          <JobStatusComponent>Failed to read Job status</JobStatusComponent>
        );
    }
  };

  const startUp = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_WORKER_URL + "/campaign/getCampaignForJob",
        {
          params: {
            campaignId: params.campaignId,
            slotId: params.slotId,
            workerId: params.workerId,
            jobId: params.jobId,
          },
        }
      );
      setSensorsSpecs(res.data.campaign.sensors);
      setCampaignDescription(res.data.campaign.description);
      setGeojson(res.data.campaign.geojson);
      setQuestion(res.data.campaign.question);
      setWorkerAnswer(res.data.jobAnswer);
      // setNoJob(true);
      if (res.data.jobId == null) {
        setNoJob(true);
      } else if (res.data.jobStatus === "ACTIVE") {
        setJobId(res.data.jobId);
      } else {
        setJobStatus(res.data.jobStatus);
        setFailReason(res.data.failReason);
      }
    } catch (err) {
      console.error(err);
      let errMessage = "Failed to get campaign" + err;
      enqueueSnackbar(errMessage, { variant: "error" });
    }
    setIsLoading(false);
  };

  // WTF are these two??
  useEffect(() => {
    populateSensorData();
  }, [sensorsSpecs]);

  const populateSensorData = async () => {
    if (!sensorsSpecs.length) {
      return;
    }
  };

  const endTask = async () => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_WORKER_URL + "/sensorData/cancel",
        { jobId: jobId }
      );
      setJobId("");
      setJobStatus("CANCEL");
    } catch (err) {
      enqueueSnackbar(`Failed to end Job`, {
        variant: "error",
      });
      console.error(err);
    }
  };

  return (
    <>
      <Backdrop
        open={isLoading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {noJob && (
        <Container
          sx={{
            height: "80vh",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Card>
            <CardContent sx={{ textAlign: "center" }}>
              You haven't completed the task in time. To start a new task follow
              this{" "}
              <a
                href={`https://ttv-sandbox.microworkers.com/dotask/info/${params.campaignId}_HG`}
              >
                link
              </a>
              .
            </CardContent>
          </Card>
        </Container>
      )}

      {/* Call jobEndSwitch() function to return the state of worker logged job */}
      {jobStatus && jobStatus !== "ACTIVE" ? (
        jobEndSwitch()
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <GpsDisableDialog open={gpsEnableDialog} nagivate={navigate} />
          {geojson.coordinates && (
            <GpsOutOfBoundDialog
              open={!withinBounds}
              coords={geojson.coordinates}
            />
          )}
          <GpsExpiredDialog open={expired} />
          {campaignDescription && (
            <Card sx={{ m: "1em", p: "1em" }}>{campaignDescription}</Card>
          )}

          {sensorsSpecs.length > 0 ? (
            sensorsSpecs.map((sensor, index) => {
              if (["Audio", "Video", "Photo"].indexOf(sensor.name) > -1) {
                return (
                  // if the required sensors are Audio, Video or Photo is created an
                  // AudioVideoSensorCollector component, which use a SensorComponent to get data
                  <AudioVideoSensorCollector
                    key={index}
                    specs={sensor}
                    jobId={jobId}
                    coords={coords}
                    setIsLoading={setIsLoading}
                    setJobStatus={setJobStatus}
                    setFailReason={setFailReason}
                  />
                );
              } else {
                return (
                  // else if the required sensors are all the others is created an
                  // SensorCollector component, which use a SensorComponent to get data
                  <SensorCollector
                    key={index}
                    specs={sensor}
                    jobId={jobId}
                    coords={coords}
                    useSensor={getSensorHook(sensor.name)}
                    setJobStatus={setJobStatus}
                    setFailReason={setFailReason}
                  />
                );
              }
            })
          ) : (
            <></>
          )}
          {question && (
            // If the crowdsourcer asked a question, here is created the component
            <QuestionComponent
              question={question}
              workerAnswer={workerAnswer}
              jobId={jobId}
              setIsLoading={setIsLoading}
              setJobStatus={setJobStatus}
              setFailReason={setFailReason}
            />
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <Button
              type="button"
              variant="contained"
              color="error"
              size="large"
              sx={{ width: "20vw", margin: "10px" }}
              onClick={() => {
                endTask();
              }}
            >
              cancel job
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}

/**
 * -----------------------------------------------------------------------
 * Question Component section
 */
const QuestionComponent = ({ question, answer, jobId, setIsLoading, setJobStatus, setFailReason }) => {
  const [disableButton, setDisableButton] = useState(true);
  const [workerAnswer, setWorkerAnswer] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (!answer) {
      setDisableButton(false);
    } else {
      setWorkerAnswer(answer);
      setDisableButton(true);
    }
  }, [answer]);

  const sendAnswer = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_WORKER_URL + "/campaign/postJobAnswer",
        {
          jobId: jobId,
          jobAnswer: workerAnswer,
        }
      );
      setDisableButton(true);
      enqueueSnackbar("Answer sent!", { variant: "success" });

      if (response.data.complete) {
        const jobRes = await axios.get(
          process.env.REACT_APP_WORKER_URL + "/campaign/getJobStatus",
          { params: { jobId } }
        );
        setJobStatus(jobRes.data.status);
        setFailReason(jobRes.data.failReason);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to send answer", { variant: "error" });
    }
    setIsLoading(false);
  };

  return (
    <Container sx={{ pt: "0.01em" }}>
      <form onSubmit={sendAnswer}>
        <Card sx={{ my: "0.8em", bgcolor: "none" }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              px: "0.5em",
            }}
          >
            <Typography
              variant="caption"
              sx={{ textTransform: "capitalize", textAlign: "center" }}
            >
              {disableButton ? (
                <>thank you for answering the question</>
              ) : (
                <>
                  The question is mandatory. Failing to answer will compromise
                  the Task.
                </>
              )}
            </Typography>
            <Typography variant="h6">{question}</Typography>

            <TextField
              disabled={disableButton}
              fullWidth
              required={true}
              multiline
              rows={2}
              value={workerAnswer}
              onChange={(ev) => {
                setWorkerAnswer(ev.target.value);
              }}
            ></TextField>
          </CardContent>
          <CardActions
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              "& > :not(:first-of-type)": {
                marginLeft: "0 !important"
              }
            }}
          >
            <Button
              type="submit"
              disabled={disableButton}
              sx={{ mb: "0.5em", width: "50vw" }}
              variant="contained"
            >
              {disableButton ? <>question answered</> : <>answer question</>}
            </Button>
            <Button
              type="button"
              disabled={!disableButton}
              variant="contained"
              color="warning"
              sx={{ mb: "0.5em", width: "50vw" }}
              onClick={() => {
                setDisableButton(false);
              }}
            >
              {<>edit answer</>}
            </Button>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

/**
 * -----------------------------------------------------------------------
 * Job Status Component section
 */
const JobStatusComponent = ({ children }) => {
  return (
    <Container
      sx={{
        height: "80vh",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Card>
        <CardContent sx={{ textAlign: "center" }}>{children}</CardContent>
      </Card>
    </Container>
  );
};

/**
 * -----------------------------------------------------------------------
 * Reducer function
 */
function reducer(state, action) {
  switch (action.type) {
    case "timestamp":
      return { ...state, timestamp: action.value };
    case "location":
      return { ...state, location: action.value };
    case "payload":
      return { ...state, payload: action.value };
    default:
      return;
  }
}

/**
 * -----------------------------------------------------------------------
 * Sensor Collector Component section
 */
const SensorCollector = ({ specs, jobId, coords, useSensor, setJobStatus, setFailReason }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { name, maxReadings, spaceInterval, timeInterval } = specs;
  const [state, dispatch] = useReducer(reducer, {
    timestamp: new Date(0),
    location: { lat: 0, lng: 0 },
    payload: "initial",
  });
  const [sensorUnavailable, setSensorUnavailable] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [readingCount, setReadingCount] = useState(0);
  const [delayCounter, setDelayCounter] = useState(0);
  const [latestPosition, setLatestPosition] = useState({
    lat: 0,
    lng: 0,
  });
  const [cooldown, setCooldown] = useState(0);
  const [timeDifference, setTimeDifferece] = useState({
    minutes: 0,
    seconds: 0,
  });
  const [spaceDifference, setSpaceDifferece] = useState(0);
  const [firstReading, setFirstReading] = useState(false);
  const { data, controller } = useSensor({ frequency: 10 });

  useInterval(
    () => {
      setCooldown(cooldown + 1);
    },
    cooldown === timeInterval ? null : 1000
  );

  useEffect(() => {
    setTimeDifferece(
      dfns.intervalToDuration({
        start: cooldown * 1000,
        end: timeInterval * 1000,
      })
    );
  }, [cooldown]);

  useEffect(() => {
    if (!coords || !latestPosition) {
      return;
    }
    const point1 = turf.point([coords.latitude, coords.longitude]);
    const point2 = turf.point([latestPosition.lat, latestPosition.lng]);
    setSpaceDifferece(Math.round(turf.distance(point1, point2) * 1000));
  }, [coords, latestPosition]);

  useEffect(() => {
    getLatestReading();
  }, []);

  // delays reading
  useEffect(() => {
    setDelayCounter(delayCounter + 1);
  }, [data]);

  useEffect(() => {
    if (!controller) {
      setSensorUnavailable(true);
      return;
    }
    if (!coords || !controller.activated) {
      return;
    }
    if (delayCounter < (useSensor === useRelativeOrientation ? 5 : 0)) {
      return;
    }
    controller.stop();
    setDelayCounter(0);

    dispatch({ type: "timestamp", value: new Date() });
    dispatch({
      type: "location",
      value: { lat: coords.latitude, lng: coords.longitude },
    });
    dispatch({ type: "payload", value: data });
  }, [delayCounter]);

  useEffect(() => {
    if (state.payload === "initial") {
      return;
    }
    postReading();
  }, [state]);

  const getLatestReading = async () => {
    const res = await axios.get(
      process.env.REACT_APP_WORKER_URL + "/sensorData/getLatestReading",
      { params: { jobId: jobId, name: name } }
    );

    if (res.data === "OK") {
      setFirstReading(true);
    } else {
      handleLatestreadings(res.data.data);
    }
  };

  const handleSendData = async () => {
    setIsSending(true);
    controller.start();
  };

  const postReading = async () => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_WORKER_URL + "/sensorData/postReading",
        { state: state, jobId: jobId, name: name }
      );
      enqueueSnackbar(`${name} sensor reading sent!`, { variant: "success" });

      // restart cooldown
      setCooldown(0);

      // update number of reading
      setReadingCount(readingCount + 1);

      // if this was first reading, now is not
      setFirstReading(false);

      // set new position
      setLatestPosition({
        lat: coords.latitude,
        lng: coords.longitude,
      });

      if (Object.keys(res.data).includes("complete")) {
        const jobRes = await axios.get(
          process.env.REACT_APP_WORKER_URL + "/campaign/getJobStatus",
          { params: { jobId } }
        );

        setJobStatus(jobRes.data.status);    // e.g. "READY_TO_RATE"
        setFailReason(jobRes.data.failReason);
      }

    } catch (err) {
      enqueueSnackbar(`Failed to send ${name} sensor reading`, {
        variant: "error",
      });
      console.error(err);
    }
    setIsSending(false);
  };

  const handleLatestreadings = (data) => {
    // count readings
    setReadingCount(data.length);

    // get latest reading
    const latest = data.sort((a, b) => {
      return new Date(b.timestamp) - new Date(a.timestamp);
    })[0];

    // set cooldown
    if (
      dfns.differenceInSeconds(new Date(), new Date(latest.timestamp)) >
      timeInterval
    ) {
      setCooldown(timeInterval);
    } else {
      const diff = dfns.differenceInSeconds(
        new Date(),
        new Date(latest.timestamp)
      );
      setCooldown(diff > timeInterval ? timeInterval : diff);
    }

    // set geo distance
    setLatestPosition(latest.location);
  };

  return (
    <SensorComponent
      onClick={() => {
        handleSendData();
      }}
      sensorUnavailable={sensorUnavailable}
      name={name}
      readingCount={readingCount}
      maxReadings={maxReadings}
      firstReading={firstReading}
      spaceDifference={spaceDifference}
      spaceInterval={spaceInterval}
      timeDifference={timeDifference}
      timeInterval={timeInterval}
      cooldown={cooldown}
      isSending={isSending}
    ></SensorComponent>
  );
};

/**
 * -----------------------------------------------------------------------
 * Audio Video Sensor section
 */
const AudioVideoSensorCollector = ({ specs, jobId, coords, useSensor, setJobStatus, setFailReason }) => {
  const { name, maxReadings, spaceInterval, timeInterval, instruction } = specs;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, {
    timestamp: new Date(0),
    location: { lat: 0, lng: 0 },
    payload: "initial",
  });

  const [sensorUnavailable, setSensorUnavailable] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [readingCount, setReadingCount] = useState(0);
  const [delayCounter, setDelayCounter] = useState(0);
  const [latestPosition, setLatestPosition] = useState({
    lat: 0,
    lng: 0,
  });
  const [cooldown, setCooldown] = useState(0);
  const [timeDifference, setTimeDifferece] = useState({
    minutes: 0,
    seconds: 0,
  });
  const [spaceDifference, setSpaceDifferece] = useState(0);
  const [firstReading, setFirstReading] = useState(true);

  useInterval(
    () => {
      setCooldown(cooldown + 1);
    },
    cooldown === timeInterval ? null : 1000
  );

  useEffect(() => {
    setTimeDifferece(
      dfns.intervalToDuration({
        start: cooldown * 1000,
        end: timeInterval * 1000,
      })
    );
  }, [cooldown]);

  useEffect(() => {
    if (!coords || !latestPosition) {
      return;
    }
    const point1 = turf.point([coords.latitude, coords.longitude]);
    const point2 = turf.point([latestPosition.lat, latestPosition.lng]);
    setSpaceDifferece(Math.round(turf.distance(point1, point2) * 1000));
  }, [coords, latestPosition]);

  useEffect(() => {
    getLatestReading();
  }, []);

  const getLatestReading = async () => {
    const res = await axios.get(
      process.env.REACT_APP_WORKER_URL + "/sensorData/getLatestReading",
      { params: { jobId: jobId, name: name } }
    );

    if (res.data === "OK") {
      setFirstReading(true);
    } else {
      setFirstReading(false);

      handleLatestreadings(res.data.data);
    }
  };

  const handleLatestreadings = (data) => {
    // count readings
    setReadingCount(data.length);

    // get latest reading
    const latest = data.sort((a, b) => {
      return new Date(b.timestamp) - new Date(a.timestamp);
    })[0];

    // set cooldown
    if (
      dfns.differenceInSeconds(new Date(), new Date(latest.timestamp)) >
      timeInterval
    ) {
      setCooldown(timeInterval);
    } else {
      const diff = dfns.differenceInSeconds(
        new Date(),
        new Date(latest.timestamp)
      );
      setCooldown(diff > timeInterval ? timeInterval : diff);
    }

    // set geo distance
    setLatestPosition(latest.location);
  };

  // ----------------- specific to audioVideo ------------------

  const [openRecordDialog, setOpenRecordDialog] = useState(false);
  const [videoReq, useVideoReq] = useState(
    name === "Video" || name === "Photo"
  );
  const [audioReq, useAudioReq] = useState(
    name === "Video" || name === "Audio" ? true : false
  );

  useEffect(() => {
    checkAudioVideo(
      (status) => {
        setSensorUnavailable(!status);
      },
      audioReq,
      videoReq
    );
  }, [videoReq, audioReq]);

  const postReading = async (url) => {
    try {
      const blobRes = await axios.get(url, { responseType: "blob" });
      const form = new FormData();

      // appends must be in this order!!
      form.append(
        "location",
        JSON.stringify({ lat: coords.latitude, lng: coords.longitude })
      );
      form.append("timestamp", new Date());
      form.append("jobId", jobId);
      form.append("name", name);
      form.append("payload", blobRes.data);

      const res = await axios.post(
        process.env.REACT_APP_WORKER_URL + "/sensorData/postReading",

        form,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      enqueueSnackbar(`${name} sensor reading sent!`, { variant: "success" });
      setFirstReading(false);

      // restart cooldown
      setCooldown(0);

      // update number of reading
      setReadingCount(readingCount + 1);

      // if this was first reading, now is not
      setFirstReading(false);

      // set new position
      setLatestPosition({
        lat: coords.latitude,
        lng: coords.longitude,
      });


      if (Object.keys(res.data).includes("complete")) {
        const jobRes = await axios.get(
          process.env.REACT_APP_WORKER_URL + "/campaign/getJobStatus",
          { params: { jobId } }
        );

        setJobStatus(jobRes.data.status);    // e.g. "READY_TO_RATE"
        setFailReason(jobRes.data.failReason);
      }
    } catch (err) {
      enqueueSnackbar(`Failed to send ${name} sensor reading`, {
        variant: "error",
      });
      console.error(err);
    }
    setIsSending(false);
    // setOpenRecordDialog(false);
  };

  return (
    <SensorComponent
      onClick={() => {
        setIsSending(true);
        setOpenRecordDialog(true);
      }}
      sensorUnavailable={sensorUnavailable}
      name={name}
      readingCount={readingCount}
      maxReadings={maxReadings}
      firstReading={firstReading}
      spaceDifference={spaceDifference}
      spaceInterval={spaceInterval}
      timeDifference={timeDifference}
      timeInterval={timeInterval}
      instruction={instruction}
      cooldown={cooldown}
      isSending={isSending}
    >
      <VideoPhotoDialog
        open={openRecordDialog}
        isPhoto={name === "Photo"}
        audio={audioReq}
        video={videoReq}
        onClose={() => {
          setIsSending(false);
          setOpenRecordDialog(false);
        }}
        onConfirm={(url) => {
          setOpenRecordDialog(false);
          postReading(url);
        }}
      />
    </SensorComponent>
  );
};

/**
 * -----------------------------------------------------------------------
 * Sensor Component section
 */
const SensorComponent = (props) => {
  const {
    sensorUnavailable,
    name,
    readingCount,
    maxReadings,
    firstReading,
    spaceDifference,
    spaceInterval,
    timeDifference,
    timeInterval,
    instruction,
    cooldown,
    isSending,
    onClick,
  } = props;

  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    setCompleted(readingCount === maxReadings);
  }, [readingCount]);
  return (
    <>
      {props.children}
      <Container sx={{ pt: "0.01em" }}>
        <Card sx={{ my: "0.8em", bgcolor: completed ? "#7AE34C" : "none" }}>
          <CardContent sx={{ px: "0.5em" }}>
            {sensorUnavailable && (
              <Typography sx={{ m: "1em", p: "1em" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Alert
                    sx={{ mb: "0.5em", flexGrow: 1 }}
                    variant="filled"
                    severity="error"
                  >
                    {name} sensor is not available with{" "}
                    {getCurrentBrowserName(navigator.userAgent)} browser, please
                    try with one of these: {checkBrowserSensorSupport(name)}
                  </Alert>
                  <Tooltip
                    sx={{ size: "30px" }}
                    title="Warning: SensorWorker is a web application and as such, 
                    it is subject to the sensor access limitations imposed by browsers. 
                    The current browser may not have access to this sensor. Furthermore, 
                    the application uses sensor interfaces that act as proxies to the real 
                    sensors present on the device. This means that even if the sensor is 
                    supported by the browser, it is not guaranteed that there is a real 
                    hardware sensor connected"
                    placement="top-start"
                  >
                    <InfoIcon
                      sx={{ mx: "0.2em", fontSize: 30, color: "#d32f2f" }}
                    />
                  </Tooltip>
                </Box>
              </Typography>
            )}
            <Box sx={{ textAlign: "center", mb: "0.3em" }}>
              <Typography variant="h5">{name}</Typography>
              {instruction ? (
                <Typography>
                  <b>Instruction</b>: {instruction}
                </Typography>
              ) : (
                <></>
              )}
            </Box>
            <Box
              sx={{
                textAlign: "center",
                bgcolor: completed ? "none" : "#B7C3D7",
                borderRadius: "0.5em",
              }}
            >
              {completed ? (
                <>COMPLETED</>
              ) : (
                <>
                  {readingCount} out of {maxReadings} submitted
                </>
              )}
            </Box>
            {!firstReading && !completed && (
              <Box
                sx={{
                  mt: "0.5em",
                  display: "flex",
                  justifyContent: "space-around",
                  fontSize: "1em",
                }}
              >
                {spaceInterval !== 0 && (
                  <Box
                    sx={{
                      textAlign: "center",
                      width: "50%",
                      mr: "0.1em",
                      borderStyle: "solid",
                      borderWidth: "0.2em",
                      borderColor: "#C5CFDF",
                      borderRadius: "0.5em",
                    }}
                  >
                    <Box>
                      Distance from <br /> last reading
                    </Box>
                    <Box>
                      {spaceDifference} m (must be {spaceInterval} m)
                    </Box>
                  </Box>
                )}
                {(timeDifference.minutes !== 0 ||
                  timeDifference.seconds !== 0) && (
                    <Box
                      sx={{
                        textAlign: "center",
                        width: "50%",
                        ml: "0.1em",
                        borderStyle: "solid",
                        borderWidth: "0.2em",
                        borderColor: "#C5CFDF",
                        borderRadius: "0.5em",
                      }}
                    >
                      <Box>
                        Cooldown before <br /> next reading
                      </Box>
                      <Box>
                        {timeDifference.minutes}:{timeDifference.seconds}
                      </Box>
                    </Box>
                  )}
              </Box>
            )}
          </CardContent>
          <CardActions
            sx={{ display: "flex", flexDirection: "column", px: "0.5em" }}
          >
            {isSending ? (
              <LoadingButton sx={{ width: "100%" }} variant="contained" loading>
                load
              </LoadingButton>
            ) : (
              <Button
                onClick={() => {
                  onClick();
                }}
                sx={{ width: "100%" }}
                variant="contained"
                disabled={
                  sensorUnavailable
                    ? true
                    : firstReading
                      ? false
                      : timeInterval > cooldown ||
                      spaceDifference < spaceInterval ||
                      maxReadings <= readingCount
                }
              >
                send
              </Button>
            )}
          </CardActions>
        </Card>
      </Container>
    </>
  );
};

/**
 * -----------------------------------------------------------------------
 * Handle GPS disabled section
 */
const GpsDisableDialog = ({ open, navigate }) => {
  return (
    <Dialog open={open}>
      <DialogTitle variant="h5" sx={{ textAlign: "center" }}>
        Gps Unavailable
      </DialogTitle>
      <DialogContent>
        <Typography>
          Geolocation is a vital part of this task, please enable GPS tracking
          on this website and/or enable the GPS sensor on the device.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Link style={{ textDecoration: "none" }} reloadDocument={true}>
          <Button variant="contained">reload</Button>
        </Link>
      </DialogActions>
    </Dialog>
  );
};

/**
 * -----------------------------------------------------------------------
 * Handle GPS out of bound section
 */
const GpsOutOfBoundDialog = ({ open, coords }) => {
  return (
    <Dialog
      open={open}
      onClick={() => {
        // console.log(coords);
      }}
    >
      <DialogTitle variant="h5" sx={{ textAlign: "center" }}>
        Out Of Bound
      </DialogTitle>
      <DialogContent>
        <Typography>
          You have gone outside the geographical bounds to perform this task.
          Please return inside the area shown below.
        </Typography>
        <MapContainer
          style={{ height: "30em" }}
          center={L.polygon(coords).getBounds().getCenter()}
        >
          <Polygon positions={coords} />
          <BoundsFitting bounds={L.polygon(coords).getBounds()} />
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        </MapContainer>
      </DialogContent>
    </Dialog>
  );
};

/**
 * -----------------------------------------------------------------------
 * Handle GPS expired section
 */
const GpsExpiredDialog = ({ open }) => {
  return (
    <Dialog open={open}>
      <DialogTitle variant="h5" sx={{ textAlign: "center" }}>
        Gps Reading Expired
      </DialogTitle>
      <DialogContent>
        <Typography>
          It seems that your geolocation reading is too old. If you have
          disabled the Gps sensor, please renable it.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default SensorCollect;
