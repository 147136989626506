import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Card,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { CheckCircle, Cancel } from "@mui/icons-material";
import {
  DeviceErrorDialog,
  ExpSensorGuideDialog,
  GpsGuideDialog,
  AudioVideoGuideDialog,
} from "../../components/Dialogs";
import { sensorCheckCardTheme } from "../../utils/themes";
import {
  checkDeviceOperatingSystem,
  checkDeviceBrowser,
  checkRegularSensors,
  checkGps,
  checkAudioVideo,
  checkNetwork,
} from "../../utils/sensorCheckFunctions";

function SensorCheck() {
  // state for OS
  const [correctDevice, setCorrectDevice] = useState(false);

  // state for browser
  const [correctBrowser, setCorrectBrowser] = useState(false);

  // state for regular sensors
  const [genericSensorEnabled, setGenericSensorEnabled] = useState(false);

  // state for experimental sensors
  const [experimentalSensorsEnabled, setExperimentalSensorsEnabled] = useState(false);
  const [expSensorGuideShow, setExpSensorGuideShow] = useState(false);

  // state for gps
  const [gpsEnabled, setGpsEnabled] = useState(null);
  const [showGpsEnableMessage, setShowGpsEnableMessage] = useState(true);
  const [gpsErrorMesssage, setGpsErrorMessage] = useState("Checking...");
  const [gpsGuideShow, setGpsGuideShow] = useState(false);

  // state for camera and microphone
  const [audioVideoEnabled, setAudioVideoEnabled] = useState(null);
  const [showAudioVideoEnableMessage, setShowAudioVideoEnableMessage] = useState(true);
  const [audioVideoErrorMesssage, setAudioVideoErrorMessage] = useState("Checking...");
  const [audioVideoGuideShow, setAudioVideoGuideShow] = useState(false);

  // state for network
  const [netEnabled, setNetEnabled] = useState(null);
  const [showNetErrorMessage, setShowNetErrorMessage] = useState(true);
  const [netErrorMesssage, setNetErrorMessage] = useState("Checking...");

  useEffect(() => {

    // Check on correct Operating System
    checkDeviceOperatingSystem(setCorrectDevice);

    // Check on correct browser for enabling or not experimental sensors
    checkDeviceBrowser(setCorrectBrowser);

    // Check on sensors enabled
    checkRegularSensors(
      { permissionName: "gyroscope", name: "Gyroscope" },
      { permissionName: "accelerometer", name: "Accelerometer" },
      setGenericSensorEnabled
    );

    checkRegularSensors(
      { permissionName: "ambient-light-sensor", name: "AmbientLightSensor" },
      { permissionName: "magnetometer", name: "Magnetometer" },
      setExperimentalSensorsEnabled
    );

    // Check on geolocation enabled
    checkGps(setGpsEnabled, setShowGpsEnableMessage, setGpsErrorMessage);

    // Check on media enabled
    checkAudioVideo(
      setAudioVideoEnabled,
      setShowAudioVideoEnableMessage,
      setAudioVideoErrorMessage
    );

    // Check on network connection
    checkNetwork(setNetEnabled, setShowNetErrorMessage, setNetErrorMessage);
  }, []);

  return (
    <Container sx={{ py: "1rem", display: "flex", flexDirection: "column" }}>
      {/* DIALOG COMPONENTS */}

      <DeviceErrorDialog open={!correctDevice} />

      <ExpSensorGuideDialog
        open={expSensorGuideShow}
        onClose={() => {
          setExpSensorGuideShow(false);
        }}
      />

      <GpsGuideDialog
        open={gpsGuideShow}
        onClose={() => {
          setGpsGuideShow(false);
        }}
      />

      <AudioVideoGuideDialog
        open={audioVideoGuideShow}
        onClose={() => {
          setAudioVideoGuideShow(false);
        }}
      />

      <ThemeProvider theme={sensorCheckCardTheme}>
        <SensorCheckCard
          title="Operating System and Browser"
          enable={correctDevice}
        />

        <SensorCheckCard
          title="Generic Sensors"
          enable={genericSensorEnabled}
        />

        <SensorCheckCard
          title="Experimental Sensors"
          enable={experimentalSensorsEnabled}
        >
          {correctBrowser ? (
            experimentalSensorsEnabled ? null : (
              <Container>
                <Button
                  sx={{ p: 0 }}
                  onClick={() => {
                    setExpSensorGuideShow(true);
                  }}
                >
                  How do i enable it?
                </Button>
              </Container>
            )
          ) : (
            <Container>
              <Typography variant="caption">
                Your browser could not enable this web feature
              </Typography>
            </Container>
          )}
        </SensorCheckCard>

        <SensorCheckCard title="GPS" enable={gpsEnabled}>
          {showGpsEnableMessage && (
            <>
              <Container>
                <Typography variant="caption">{gpsErrorMesssage}</Typography>
              </Container>
              <Container>
                <Button
                  sx={{ p: 0 }}
                  onClick={() => {
                    setGpsGuideShow(true);
                  }}
                >
                  How do i enable it?
                </Button>
              </Container>
            </>
          )}
        </SensorCheckCard>

        <SensorCheckCard title="Camera & Microphone" enable={audioVideoEnabled}>
          {showAudioVideoEnableMessage && (
            <>
              <Container>
                <Typography variant="caption">
                  {audioVideoErrorMesssage}
                </Typography>
              </Container>
              <Container>
                <Button
                  sx={{ p: 0 }}
                  onClick={() => {
                    setAudioVideoGuideShow(true);
                  }}
                >
                  How do i enable it?
                </Button>
              </Container>
            </>
          )}
        </SensorCheckCard>
        <SensorCheckCard title="Network Sensor" enable={netEnabled}>
          {showNetErrorMessage && (
            <Container>
              <Typography variant="caption">{netErrorMesssage}</Typography>
            </Container>
          )}
        </SensorCheckCard>
      </ThemeProvider>
    </Container>
  );
}

const SensorCheckCard = ({ title, enable, children }) => {
  return (
    <Card>
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>{title}</Typography>
        {enable ? (
          <CheckCircle fontSize="large" color="success" />
        ) : (
          <Cancel fontSize="large" color="error" />
        )}
      </Container>
      {children}
    </Card>
  );
};

export default SensorCheck;
