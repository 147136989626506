import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Box, Skeleton, Typography } from "@mui/material";
import axios from "axios";
import { useReactMediaRecorder } from "react-media-recorder";
import Stopwatch from "./Stopwatch";

function VideoComponent({
  audio = true,
  video = true,
  onStart,
  onStop,
  isPhoto = false,
  timeLimit = { minutes: 0, seconds: 15 },
  onConfirm = () => { },
}) {
  const [recordBtnMsg, setRecordBtnMsg] = useState("start");
  const [recordBtnColor, setRecordBtnColor] = useState("success");
  const [photoUrl, setPhotoUrl] = useState(false);
  const [showPreview, setShowPreview] = useState(true);
  const videoRef = useRef(null);

  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    previewStream,
    clearBlobUrl,
  } = useReactMediaRecorder({
    video: video ? { facingMode: "environment" } : false,
    audio: audio,
    onStart: onStart,
    onStop: () => {
      onStop();
      // console.log("ronin");
    },
    askPermissionOnMount: true,
    stopStreamsOnStop: true,
  });

  const takePic = useCallback(async () => {
    // get the video element from your ref
    const video = videoRef.current;
    if (!video) return;

    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    canvas.toBlob((blob) => {
      if (!blob) return;
      const url = URL.createObjectURL(blob);
      setPhotoUrl(url);
      setShowPreview(false);
    }, "image/png");
  }, [previewStream]);

  const handleStartRecording = async () => {
    // console.log(previewStream);
    setRecordBtnMsg("stop");
    setRecordBtnColor("warning");
    setShowPreview(true);
    startRecording();
  };
  const handleStopRecording = async () => {
    setRecordBtnMsg("start");
    setRecordBtnColor("success");
    setShowPreview(false);
    stopRecording();
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {showPreview && (
        <VideoPreview stream={previewStream} videoRef={videoRef}>
          {!isPhoto ? (
            <>
              {status === "recording" && (
                <Box
                  sx={{
                    my: "0.5em",
                    display: "flex",
                    alignItems: "center",
                    position: "absolute",
                    top: "5%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1,
                    bgcolor: "rgba(255, 255, 255, 0.5)",
                    px: "0.3em",
                    py: "0.3em",
                    borderRadius: "0.5em",
                  }}
                >
                  <Skeleton
                    sx={{ bgcolor: "red" }}
                    variant="circular"
                    width={"1.5em"}
                    height={"1.5em"}
                  ></Skeleton>
                  <Typography sx={{ ml: "0.4em" }}>
                    <Stopwatch
                      limit={timeLimit}
                      callback={handleStopRecording}
                    />
                  </Typography>
                </Box>
              )}
              <Button
                sx={{
                  position: "absolute",
                  top: !video && audio ? "50%" : "90%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                }}
                color={recordBtnColor}
                variant="contained"
                size="large"
                onClick={() => {
                  if (status === "idle" || status === "stopped") {
                    handleStartRecording();
                  } else {
                    handleStopRecording();
                  }
                }}
              >
                {recordBtnMsg}
              </Button>
            </>
          ) : (
            <Button
              sx={{
                position: "absolute",
                top: "90%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
              }}
              color="success"
              variant="contained"
              size="large"
              onClick={takePic}
            >
              take photo
            </Button>
          )}
        </VideoPreview>
      )}
      {photoUrl && (
        <PhotoTaken
          photoUrl={photoUrl}
          setPhotoUrl={setPhotoUrl}
          setShowPreview={setShowPreview}
          onConfirm={onConfirm}
        />
      )}
      {mediaBlobUrl && (
        <VideoTaken
          url={mediaBlobUrl}
          setShowPreview={setShowPreview}
          clearBlobUrl={clearBlobUrl}
          onConfirm={onConfirm}
        />
      )}
    </Box>
  );
}

function VideoPreview({ stream, children, videoRef }) {

  useEffect(() => {
    if (videoRef.current && !videoRef.current.srcObject && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream, videoRef]);

  // useUpdateEffect(() => {
  //   return () => {
  //     // stream.getTracks();
  //     console.log("ronin");
  //   };
  // }, [stream]);

  if (!stream) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <video
        style={{ borderRadius: "0.5em" }}
        width={"100%"}
        ref={videoRef}
        autoPlay
        playsInline
        webkit-playsinline
      />
      {children}
    </Box>
  );
}

const PhotoTaken = ({ photoUrl, setPhotoUrl, setShowPreview, onConfirm }) => {
  const sendPhoto = () => {
    axios
      .get(photoUrl, { responseType: "blob" })
      .then((res) => {
        const form = new FormData();
        form.append("payload", res.data);
        axios
          .post(
            process.env.REACT_APP_WORKER_URL + "/sensorData/postReading",
            form,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          )
          .then((res) => { });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        src={photoUrl}
        style={{ borderRadius: "0.5em" }}
        width={"100%"}
        autoPlay
      />
      <Box
        sx={{
          display: "flex",
          position: "absolute",
          top: "90%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1,
        }}
      >
        <Button
          sx={{ mx: "0.5em" }}
          color="success"
          variant="contained"
          size="large"
          onClick={() => {
            onConfirm(photoUrl);
            // sendPhoto();
          }}
        >
          send
        </Button>
        <Button
          sx={{ mx: "0.5em" }}
          color="error"
          variant="contained"
          size="large"
          onClick={() => {
            setPhotoUrl(false);
            setShowPreview(true);
          }}
        >
          retake
        </Button>
      </Box>
    </Box>
  );
};

function VideoTaken({ url, setShowPreview, clearBlobUrl, onConfirm }) {
  return (
    <Box>
      <video
        style={{ borderRadius: "0.5em" }}
        width={"100%"}
        src={url}
        controls
      />
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          variant="contained"
          size="large"
          color="error"
          onClick={() => {
            setShowPreview(true);
            clearBlobUrl();
          }}
        >
          retake
        </Button>
        <Button
          variant="contained"
          size="large"
          color="success"
          onClick={() => {
            onConfirm(url);
            // sendPhoto();
          }}
        >
          send
        </Button>
      </Box>
    </Box>
  );
}

export default VideoComponent;
