import {
  useAbsoluteOrientation,
  useAccelerometer,
  useGravity,
  useGyroscope,
  useLightSensor,
  useLinearAcceleration,
  useMagnetometer,
  useRelativeOrientation,
} from "../sensors/basicSensorHook";
import * as rdd from "react-device-detect";

/**
 * Check on correct Operating System
 */
export const checkDeviceOperatingSystem = (setter) => {

  // Android Operating System
  if (rdd.isAndroid || rdd.isIOS) {
    setter(true);
  }
  //  Other Operating System
  else {
    setter(false);
  }
};


/**
 * Check on correct browser to verify if the experimental sensors can be enabled
 */
export const checkDeviceBrowser = (setter) => {

  if (rdd.isChrome || rdd.isEdge || rdd.isChromium || rdd.isEdgeChromium) {
    setter(true);
  }
  // if rdd.isSafari || rdd.isMobileSafari || rdd.isFirefox || rdd.isOpera || rdd.isSamsungBrowser
  else {
    setter(false);
  }
};

export const checkRegularSensors = async (sensor1, sensor2, setter) => {
  try {
    const resultSensor1 = await navigator.permissions.query({
      name: sensor1.permissionName,
    });
    const resultSensor2 = await navigator.permissions.query({
      name: sensor2.permissionName,
    });
    if (
      resultSensor1.state !== "granted" ||
      resultSensor2.state !== "granted"
    ) {
      throw new Error("Permission denied");
    }

    const sensor1Instance = new window[sensor1.name]();
    const sensor2Instane = new window[sensor2.name]();

    sensor1Instance.onerror = (error) => {
      console.error(sensor1.name + " not supported: " + error);
      setter(false);
    };
    sensor2Instane.onerror = (error) => {
      console.error(sensor2.name + " not supported: " + error);
      setter(false);
    };

    sensor1Instance.start();
    sensor2Instane.start();

    sensor1Instance.stop();
    sensor2Instane.stop();

    setter(true);
  } catch (error) {
    setter(false);
    console.error(error);
  }
};

export const checkRegularSensor = async (sensor, setter) => {
  try {
    const resultSensor = await navigator.permissions.query({
      name: sensor.permissionName,
    });

    if (resultSensor.state !== "granted") {
      throw new Error("Permission denied");
    }

    const sensorInstance = new window[sensor.name]();

    sensorInstance.onerror = (error) => {
      console.error(sensor.name + " not supported: " + error);
      setter(false);
    };

    sensorInstance.start();

    sensorInstance.stop();

    setter(true);
  } catch (error) {
    setter(false);
    console.error(error);
  }
};

export const checkGps = async (
  setter,
  showMsg = (tmp) => {},
  setMsg = (tmp) => {}
) => {
  try {
    const result = await navigator.permissions.query({
      name: "geolocation",
    });
    if (result.state === "denied") {
      // console.log("gps is denied");
      throw new Error("Permission denied: gps");
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setter(true);
        showMsg(false);
      },
      (error) => {
        console.error(error);
        if (error.code === 1) {
          setter(false);
          showMsg(true);
          setMsg("Please enable GPS in your device settings");
        } else {
          setter(false);
          showMsg(true);
          setMsg("Error accured while getting GPS location");
        }
      }
    );
  } catch (error) {
    setter(false);
    setMsg("Please grant access to GPS location");
    showMsg(true);
    console.error(error);
  }
};

export const checkAudioVideo = async (
  setter,
  audioReq,
  videoReq,
  showMsg = (tmp) => {},
  setMsg = (tmp) => {}
) => {
  try {
    if (videoReq) {
      // console.log("asking for video");
      const cameraResult = await navigator.permissions.query({
        name: "camera",
      });
      if (cameraResult.state === "denied") {
        // console.log("camera microphone is denied");
        throw new Error("Permission denied: camera");
      }
    }

    if (audioReq) {
      // console.log("asking for audio");

      const microphoneResult = await navigator.permissions.query({
        name: "microphone",
      });

      if (microphoneResult.state === "denied") {
        // console.log("microphone is denied");
        throw new Error("Permission denied: microphone");
      }
    }
    // console.log("requirements: ", audioReq, videoReq);
    navigator.mediaDevices
      .getUserMedia({
        audio: audioReq,
        video: videoReq
          ? {
              facingMode: "environment",
            }
          : false,
      })
      .then((stream) => {
        // console.log("video and/or audio is gained successfully: ", stream);
        setter(true);
        showMsg(false);
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      })
      .catch((reason) => {
        console.error("Failed to gain stream: " + reason);
        setter(false);
        showMsg(true);
        setMsg("Please enable access to camera and microphone");
      });
  } catch (error) {
    setter(false);
    setMsg("Please grant access to camera and microphone");
    showMsg(true);
    console.error(error);
  }
};

export const checkNetwork = (
  setter,
  showMsg = (tmp) => {},
  setMsg = (tmp) => {}
) => {
  try {
    const type = navigator.connection.type;
    if (type === "none" || type === "unknown") {
      throw new Error("Network is not available");
    }
    setter(true);
    showMsg(false);
  } catch (error) {
    setter(false);
    setMsg("Please enable network connection");
    showMsg(true);
    console.error("Network Check error: " + error);
  }
};

export const sensorNameTranslation = (name) => {
  switch (name) {
    case "Accelerometer":
      return { name: "Accelerometer", permissionName: "accelerometer" };
    case "Gyroscope":
      return { name: "Gyroscope", permissionName: "gyroscope" };
    case "Magnetometer":
      return { name: "Magnetometer", permissionName: "magnetometer" };
    case "AmbientLightSensor":
      return {
        name: "AmbientLightSensor",
        permissionName: "ambient-light-sensor",
      };

    default:
      return;
  }
};

export const getSensorHook = (name) => {
  switch (name) {
    case "Accelerometer":
      return useAccelerometer;

    case "Gyroscope":
      return useGyroscope;

    case "Magnetometer":
      return useMagnetometer;

    case "AmbientLightSensor":
      return useLightSensor;

    case "GravitySensor":
      return useGravity;

    case "LinearAccelerationSensor":
      return useLinearAcceleration;

    case "RelativeOrientationSensor":
      return useRelativeOrientation;

    case "AbsoluteOrientationSensor":
      return useAbsoluteOrientation;

    default:
      return useAccelerometer;
  }
};
