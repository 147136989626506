export function getFloatFixedValue(value, fractionDigits) {
  var parseFloatValue = parseFloat(value);
  if (fractionDigits !== undefined) {
    return parseFloatValue.toFixed(fractionDigits);
  }
  return parseFloatValue;
}

export const sensorNameArray = [
  "Accelerometer",
  "Gyroscope",
  "Magnetometer",
  "AmbientLightSensor",
  "GravitySensor",
  "LinearAccelerationSensor",
  "RelativeOrientationSensor",
  "AbsoluteOrientationSensor",
  "Photo",
  "Audio",
  "Video",
];

export const browserNamesArray = [
  "Google Chrome",
  "Mozilla Firefox",
  "Apple Safari",
  "Microsoft Edge",
  "Opera",
  "Samsung Internet"
];

/**
 * Check whether the device is a mobile or desktop device
 */
const isMobile = () => {
  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return regex.test(navigator.userAgent);
};

/**
 * Check if your device has touchscreen enabled
 */
const hasTouchSupport = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

/**
 * @returns true if client is on a mobile device, false if he is on a desktop device
 */
export const isMobileWithTouchSupport = () => {
  return isMobile() && hasTouchSupport();
};

/**
 * Warning: this method must be updated frequently with new Browsers to identify the correct one.
 * Order matters here, this may report false positives for unlisted browsers
 * @param {*} userAgent a string from navigator.userAgent
 * @returns Browser name currently used
 */
export const getCurrentBrowserName = (userAgent) => {
  if (userAgent.includes("Firefox")) {
    // "... Firefox/104.0"
    return "Mozilla Firefox";
  } else if (userAgent.includes("SamsungBrowser")) {
    // "... SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36"
    return "Samsung Internet";
  } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
    // "... Chrome/104.0.0.0 Safari/537.36 OPR/90.0.4480.54"
    return "Opera";
  } else if (userAgent.includes("Edge") || userAgent.includes("Edg")) {
    // "... Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299 ... or Edg/104.0.1293.70"
    return "Microsoft Edge";
  } else if (userAgent.includes("Chrome")) {
    // "... Chrome/104.0.0.0 Safari/537.36"
    return "Google Chrome";
  } else if (userAgent.includes("Safari")) {
    // "... Mobile/15E148 Safari/604.1"
    return "Apple Safari";
  } else {
    return "Unknown";
  }
};

/**
 * List of sensors available to each browser
 */
const sensorBrowserAvailability = {
  Accelerometer: "Samsung Internet, Opera, Microsoft Edge, Google Chrome",
  GravitySensor: "Samsung Internet, Opera, Microsoft Edge, Google Chrome",
  Gyroscope: "Samsung Internet, Opera, Microsoft Edge, Google Chrome",
  LinearAccelerationSensor: "Samsung Internet, Opera, Microsoft Edge, Google Chrome",
  AbsoluteOrientationSensor: "Samsung Internet, Opera, Microsoft Edge, Google Chrome",
  RelativeOrientationSensor: "Samsung Internet, Opera, Microsoft Edge, Google Chrome",
  Magnetometer: "Microsoft Edge, Google Chrome",
  AmbientLightSensor: "Microsoft Edge, Google Chrome",
  Photo: "Mozilla Firefox, Samsung Internet, Opera, Microsoft Edge, Google Chrome, Apple Safari",
  Audio: "Mozilla Firefox, Samsung Internet, Opera, Microsoft Edge, Google Chrome, Apple Safari",
  Video: "Mozilla Firefox, Samsung Internet, Opera, Microsoft Edge, Google Chrome, Apple Safari",
};

/**
 * This function takes in input a sensor name used by the campaign the worker is working on
 * and checks if there are some sensors that are not supported from the browser he is using
 * @param sensorName, sensor name used by current campaign
 * @returns list of browsers which support the sensor
 */
export const checkBrowserSensorSupport = (sensorName) => {
  const currentBrowser = getCurrentBrowserName(navigator.userAgent);

  if (sensorBrowserAvailability.hasOwnProperty(sensorName)) {
    let supportedBrowsers = sensorBrowserAvailability[sensorName];

    if (supportedBrowsers.includes(currentBrowser)) {
      // Remove current browser from the list to return (security)
      supportedBrowsers = supportedBrowsers.replace(currentBrowser + ",", "");

      // Delete the comma if it's the last character of the list
      if (supportedBrowsers.slice(-1) === ",") {
        supportedBrowsers = supportedBrowsers.slice(0, -1);
      }
    }

    return supportedBrowsers;
  } else {
    return ["Not supported by any browser"];
  }
};

/**
 * This function takes in input an array of sensors used by the campaign the worker is working on
 * and checks if there are some sensors that are not supported from the browser he is using
 * @param sensors, the array of current campaign sensors
 * @returns list of browsers which support all campaign's sensors separated by a comma
 */
export const checkBrowserSensorSupport2 = (sensors) => {
  let allBrowsers = browserNamesArray;

  // Iterate over each sensor and delete browsers that don't support it
  for (let sensor of sensors) {
    if (
      sensor === "Accelerometer" ||
      sensor === "GravitySensor" ||
      sensor === "Gyroscope" ||
      sensor === "LinearAccelerationSensor" ||
      sensor === "AbsoluteOrientationSensor"||
      sensor === "RelativeOrientationSensor"
    ) {
      allBrowsers = allBrowsers.filter(
        (browser) =>
          browser !== "Mozilla Firefox" &&
          browser !== "Apple Safari"
      );
    } else if (sensor === "AmbientLightSensor" || sensor === "Magnetometer") {
      allBrowsers = allBrowsers.filter(
        (browser) =>
          browser !== "Mozilla Firefox" &&
          browser !== "Opera" &&
          browser !== "Samsung Internet" &&
          browser !== "Apple Safari"
      );
    }
  }

  return allBrowsers.join(", ");
};
