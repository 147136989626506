import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  TextField,
  Button,
  Divider,
  Box,
} from "@mui/material";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";
import axios from "axios";
import { useSnackbar } from "notistack";

function SensorCollectQR() {
  const [params, setParams] = useSearchParams();
  const routerNavigate = useNavigate();
  const [workerIdAvail, setWorkerIdAvail] = useState(false);
  const [workerId, setWorkerId] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [qrUrl, setQrUrl] = useState("");
  const [campaignTitle, setCampaignTitle] = useState("");

  useEffect(() => {
    if (params.get("workerId") === "DummyWorkerId") {
      //preview, so straight to campaignSelect
      setWorkerIdAvail(false);
    } else {
      searchWorkerId(params.get("workerId"));
      setWorkerIdAvail(true);
    }
  }, []);

  const handleWorkerIdInput = async (e) => {
    if (workerId) {
      e.preventDefault();
      searchWorkerId(workerId);
    }
  };

  const searchWorkerId = async (WID) => {
    axios
      .get(process.env.REACT_APP_WORKER_URL + "/campaign/getWorkerActiveJob", {
        params: {
          workerId: WID,
          campaignIDMW: params.get("campaignId"),
        },
      })
      .then((res) => {
        if (res.data === "OK") {
          setWorkerId("");
          enqueueSnackbar("No active task found!", { variant: "error" });
        } else {
          setCampaignTitle(res.data.campaignID.title);
          setQrUrl(
            `/sensorCollect/${res.data.campaignID.id}/${res.data.slotIDMW}/${res.data.workerIDMW}/${res.data._id}`
          );
          setWorkerIdAvail(true);
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
      });
  };

  return (
    <>
      {workerIdAvail ? (
        <Container
          sx={{
            height: "80vh",
            mt: "2em",
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card>
            <CardContent>
              <Box sx={{ my: "1.5em", px: "1em" }}>
                <Typography sx={{ fontSize: "1.5em", fontWeight: "bold" }}>
                  Scan the following QR Code with your phone to start or resume
                  your task for campaign:
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ my: "0.3em", fontWeight: "1000" }}
                >
                  {campaignTitle}
                </Typography>
                <QRCodeSVG
                  size={250}
                  value={process.env.REACT_APP_URL + qrUrl}
                />
              </Box>
              <Divider variant="middle" sx={{ my: "0.2em" }}>
                OR
              </Divider>
              <Typography sx={{ fontSize: "1.5em", fontWeight: "bold" }}>
                If you are already on your phone,
              </Typography>
              <Typography sx={{ fontSize: "1.5em" }}>
                Click this{" "}
                <Link to={qrUrl} target="_blank">
                  Link
                </Link>
              </Typography>

              <Typography sx={{ mt: "1em" }}>
                For any question, email us at{" "}
                <Typography variant="button">
                  {"  "}
                  help.sensorworker@gmail.com{"  "}
                </Typography>
              </Typography>
            </CardContent>
          </Card>
        </Container>
      ) : (
        <Container
          sx={{
            display: "flex",
            height: "80vh",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card sx={{ p: "2em" }}>
            <form>
              <CardContent>
                <Typography variant="h3">Task Search</Typography>
                <Typography mb="0.5em">
                  Write your worker ID retreive the QR code for your running
                  task
                </Typography>
                <TextField
                  required={true}
                  variant="outlined"
                  placeholder="worker ID"
                  value={workerId}
                  onChange={(e) => {
                    setWorkerId(e.target.value);
                  }}
                />
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleWorkerIdInput}
                >
                  Search
                </Button>
              </CardActions>
            </form>
          </Card>
        </Container>
      )}
    </>
  );
}

export default SensorCollectQR;
