import React, { useState } from "react";
import axios from "axios";
import Homepage from "./pages/Homepage";
import Register from "./pages/Register";
import Login from "./pages/Login";
import CreateCampaign from "./pages/CreateCampaign";
import Main from "./pages/Main";
import SensorCheck from "./pages/worker/SensorCheck";
import CampaignSelect, { IntroStepper } from "./pages/worker/CampaignSelect";
import SensorCollect from "./pages/worker/sensorCollect";
import JobTimeExpired from "./pages/worker/JobTimeExpired";
import SensorCollectQR from "./pages/worker/sensorCollectQR";
import Data from "./pages/Data";
import DataView from "./pages/DataView";
import Disclaimer from "./pages/worker/Disclaimer";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { SnackbarProvider } from "notistack";
// import "./index.css";
import { Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

const router = createBrowserRouter([
  {
    path: "/crowdsourcer",
    element: <Homepage />,
    action: () => {},
    children: [
      {
        path: "/crowdsourcer",
        element: <Main />,
      },
      {
        path: "/crowdsourcer/createCampaign",
        element: <CreateCampaign />,
      },
      {
        path: "/crowdsourcer/showData/:campaignId",
        element: <Data />,
      },
    ],
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/",
    element: <Login />,
  },

  {
    path: "/sensorCheck",
    element: <SensorCheck />,
  },
  {
    path: "/campaignSelect",
    element: <CampaignSelect />,
  },
  {
    path: "/intro",
    element: <IntroStepper />,
  },
  {
    path: "/sensorCollect/:campaignId/:slotId/:workerId/:jobId",
    element: <SensorCollect />,
  },
  {
    path: "/timeExpired",
    element: <JobTimeExpired />,
  },
  {
    path: "/sensorCollectQR",
    element: <SensorCollectQR />,
  },
  {
    path: "/data",
    element: <DataView />,
  },
  {
    path: "/disclaimer",
    element: <Disclaimer />,
  },
  {
    path: "/serverError",
    element: <div>Server error... (App.js)</div>,
    action: () => {},
  },
]);

axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      router.navigate("/", { replace: true });
    }

    if (error.response.status === 403) {
      router.navigate("/timeExpired");
    }
    return Promise.reject(error);
  }
);

function App() {
  const [color, changeColor] = useState("#5572a1");

  document.body.style.backgroundColor = color;

  return (
    <SnackbarProvider anchorOrigin={{ horizontal: "center", vertical: "top" }}>
      <CssBaseline>
        <Box>
          <RouterProvider router={router} />
        </Box>
      </CssBaseline>
    </SnackbarProvider>
  );
}

export default App;
